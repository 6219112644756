import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { ApplicationTagList } from '../../../../entities/v1/applicant_tracking/ApplicationTagList';

export interface ListParams {
  applicationId: number;
  expand?: string[];
}

export class ApplicationTagService {
  public static list(params: ListParams): Promise<ApplicationTagList> {
    return ApiService.get<ApplicationTagList>(
      '/api/v1/applicant_tracking/application_tags',
      snakecaseKeys(params),
    );
  }

  public static delete(id: number): Promise<void> {
    return ApiService.delete(
      `/api/v1/applicant_tracking/application_tags/${id}`,
    );
  }
}
