import React, { useEffect, useState } from 'react';
import { SearchSegment } from '../../../../../../../../entities/v1/applicant_tracking/SearchSegment';
import {
  GroupedOption,
  GroupedSelect,
} from '../../../../../../../../components/GroupedReactSelect';
import { SelectOption } from '../../../../../../../../components/Select';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PropTypes {
  segments: SearchSegment[];
  selectedSegment: SearchSegment;
  setSelectedSegment: (segment: SearchSegment) => void;
}

function isSelectedSegment(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
) {
  if (selectedSegment === null) return segment.isDefault;

  return segment.id === selectedSegment.id;
}

function mapSegmentToOption(
  segment: SearchSegment,
  selectedSegment: SearchSegment,
): SelectOption {
  return {
    value: segment.id.toString(),
    label: segment.name,
    selected: isSelectedSegment(segment, selectedSegment),
  };
}

function buildGroupedOptions(
  segments: SearchSegment[],
  selectedSegment: SearchSegment,
): GroupedOption[] {
  return [
    {
      label: 'Public',
      options: segments
        .filter((segment) => !segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
    {
      label: 'My Own',
      options: segments
        .filter((segment) => segment.isPrivate)
        .map((segment) => mapSegmentToOption(segment, selectedSegment)),
    },
  ];
}

function selectedSegment(
  options?: GroupedOption[],
  selectedSegment?: SearchSegment,
) {
  if (!selectedSegment || !options) {
    return null;
  }

  return options
    .flatMap((group) => group.options)
    .find((option) => option.selected);
}

export function SegmentSelect(props: PropTypes) {
  const [options, setOptions] = useState<GroupedOption[]>(null);

  useEffect(() => {
    if (props.segments && props.selectedSegment) {
      setOptions(buildGroupedOptions(props.segments, props.selectedSegment));
    }
  }, [props.selectedSegment]);

  return (
    <div className={classNames('ms-3', styles['select-container'])}>
      <GroupedSelect
        mandatory
        isSearchable
        isClearable={false}
        options={options || []}
        isLoading={options == null}
        selected={selectedSegment(options, props.selectedSegment)}
        onChange={(option) =>
          props.setSelectedSegment(
            props.segments.find((i) => i.id.toString() === option.value),
          )
        }
      />
    </div>
  );
}
