import React from 'react';
import { Chip } from '../../../../../../../../components/Chip';
import { jobStageChipColor } from '../../../../../../../../utils/jobStageChipColor';
import { getDurationInMaxUnit } from '../../../../../../../../utils/duration';

interface PropTypes {
  stageName: string;
  millisInStage: number;
}

export default function Stages(props: PropTypes) {
  if (props.stageName == null)
    return (
      <div className='d-flex justify-content-center'>
        <span>-</span>
      </div>
    );

  const timeInStage = getDurationInMaxUnit(props.millisInStage, [
    'minutes',
    'hours',
    'days',
    'months',
  ]);

  return (
    <div className='d-flex align-items-center'>
      <Chip
        className={'py-1 px-2h fs-6 fw-semibold'}
        color={jobStageChipColor(props.stageName)}
      >
        {props.stageName}
      </Chip>
      <small className='text-muted text-truncate ms-2'>
        {timeInStage.value != null &&
          `${timeInStage.value} ${timeInStage.unit}`}
      </small>
    </div>
  );
}
