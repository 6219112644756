import React, { useEffect, useState } from 'react';
import { Chip, ChipColor } from '../../../../components/Chip';
import { Card } from '../../../../components/Card';
import { ApplicationTagList } from '../../../../entities/v1/applicant_tracking/ApplicationTagList';
import { Application } from '../../../../entities/Application';
import { ApplicationTagService } from '../../../../services/v1/applicant_tracking/ApplicationTagService';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { TagChip } from './TagChip';
import { ApplicationTag } from '../../../../entities/v1/applicant_tracking/ApplicationTag';

interface PropTypes {
  application: Application;
}

export function TagsPanel(props: PropTypes) {
  const [applicationTags, setApplicationTags] = useState<ApplicationTag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    ApplicationTagService.list({
      applicationId: props.application.id,
      expand: ['tag'],
    })
      .then((response: ApplicationTagList) =>
        setApplicationTags(response.applicationTags),
      )
      .finally(() => setIsLoading(false));
  }, []);

  function onDelete(id: number) {
    ApplicationTagService.delete(id).then(() => {
      setApplicationTags((prevTags) => prevTags.filter((tag) => tag.id !== id));
    });
  }

  return (
    <Card className='gap-3'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='d-flex'>
            <h3 className='mb-0 me-auto'>Tags</h3>

            <Chip color={ChipColor.BlueLight}>
              <i className='bi bi-tags me-1'></i>
              <span className='fs-6 fw-semibold'>{applicationTags.length}</span>
            </Chip>
          </div>
          <div className='d-flex flex-wrap gap-2'>
            {applicationTags.map((applicationTag) => (
              <div key={applicationTag.id}>
                <TagChip
                  applicationTag={applicationTag}
                  onDelete={onDelete}
                  upsertTagsEnabled={props.application.upsertTagsEnabled}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
}
